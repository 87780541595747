<template>
    <div class="preview-layer" v-if="visible" @click="closePreviewModal">
        <div class="preview-view" @click.stop="() => {}">
            <RealPhonePreview>
                <template v-slot:preview>
                    <div class="notice-panel">
                        <div class="notice-header">
                            <div class="notice-title">
                                {{ notice.title }}
                            </div>
                            <div class="notice-time">
                                {{ notice.createTime }}
                            </div>
                        </div>
                        <div
                            class="notice-content"
                            v-html="notice.content"
                        ></div>
                    </div>
                </template>
            </RealPhonePreview>
            <slot name="btnGroup"></slot>
        </div>
    </div>
</template>

<script>
import RealPhonePreview from "@/components/RealPhonePreview";

export default {
    name: "NoticePhonePreview",
    components: {
        RealPhonePreview,
    },
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        notice: [Object, Array],
    },
    data() {
        return {};
    },
    methods: {
        // 关闭弹窗
        closePreviewModal() {
            this.$emit("closeModal");
        },
    },
    mounted() {},
};
</script>

<style lang="stylus" scoped>
.notice
    &-panel
        background #f0f0f0
        height 100%
        width 100%
    &-header
        background #fff
        font-size calc((30px / 2.5))
        margin-bottom 4px
        padding calc((26px / 2.5)) calc((34px / 2.5))
        .notice-title
            margin-bottom calc((16px / 2.5))
        .notice-time
            color #999
            font-size calc((26px / 2.5))
    &-content
        background #fff
        padding calc((30px / 2.5)) calc((34px / 2.5))
</style>