<template>
  <div id="proclamation_">
    <div class="filter-panel">
      <CSSelect style="width: 210px; border-radius: 4px; margin-right: 0">
        <el-date-picker
            v-model="startDate"
            :editable="false"
            format="yyyy-MM-dd HH:mm"
            placeholder="请选择开始时间"
            style="width: 182px"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm"
        ></el-date-picker>
      </CSSelect>
      <span class="inline-gray"></span>
      <CSSelect style="width: 210px; border-radius: 4px">
        <el-date-picker
            v-model="endDate"
            :editable="false"
            format="yyyy-MM-dd HH:mm"
            placeholder="请选择结束时间"
            style="width: 182px"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm"
        ></el-date-picker>
      </CSSelect>
      <input
          v-model="userInfo"
          class="cs-input"
          placeholder="搜索公告名称"
          style="border-radius: 4px"
          type="text"
      />
      <CSSelect style="border-radius: 4px; margin-right: 0">
        <select v-model="announcementState" style="padding-left: 5px">
          <option style="color: #000" value="">公告状态不限</option>
          <option
              v-for="(name, state) in orderStates"
              :key="state"
              :value="name.id"
              style="color: #000"
          >
            {{ name.name }}
          </option>
        </select>
      </CSSelect>
      <button
          class="btn_query"
          style="width: 50px; height: 30px; padding: 0"
          @click="getNotice()"
      >
        查询
      </button>
    </div>
    <!-- 主体区域 -->
    <div class="result-panel">
      <CSTable :thead-top="filterHeight">
        <template v-slot:header>
          <div class="table-header-panel text-right">
            <div class="btn btn-primary sticky-right" @click="openAddAnnouncement">
              <svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-menujiahao"></use>
              </svg>
              添加公告
            </div>
          </div>
        </template>
        <template v-slot:thead>
          <tr>
            <th>添加时间</th>
            <th>公告名称</th>
            <th>展示位</th>
            <th>发布时间</th>
            <th>下架时间</th>
            <th>公告状态</th>
            <th>查看人数</th>
            <th>操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <tr v-for="item in noticeList" :key="item.id">
            <td class="text-center date-td">
              {{ item.createTime }}
            </td>
            <td class="text-center">
              <span
                  class="allow-click"
                  @click="lookAnnouncementInformation(item.postId)"
              >
                {{ item.title }}
              </span>
            </td>
            <td class="text-center">
              {{ orderTypeStr[item.type] }}
            </td>
            <td class="text-center date-td">{{ item.releaseTime }}</td>
            <td class="text-center date-td">{{ item.unshelveTime }}</td>
            <td class="text-center">
              {{ orderState[item.state] }}
            </td>

            <td class="text-center">{{ item.uvCount }}</td>
            <td class="text-center">
              <div class="btn-group">
                <button
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="btn btn-primary dropdown-toggle"
                    data-toggle="dropdown"
                    type="button"
                >
                  操作
                </button>
                <ul class="dropdown-menu">
                  <li v-if="item.state != 2">
                    <a
                        style="width: 100%"
                        @click="
                        putawayId = item.id;
                        putawayAnnouncement = true;
                      "
                    >上架公告</a
                    >
                  </li>
                  <li v-if="item.state === 2">
                    <a
                        style="width: 100%"
                        @click="
                        soldOutId = item.id;
                        soldOutAnnouncement = true;
                      "
                    >下架公告</a
                    >
                  </li>
                  <li>
                    <a
                        style="width: 100%"
                        @click="
                        deleteAnnouncement = true;
                        currentItemId = item.id;
                      "
                    >删除公告</a
                    >
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </template>
      </CSTable>
      <Pagination component="Pagination" name="Pagination"></Pagination>
    </div>
    <!--添加公告弹出框 -->
    <CSDialog
        :dialogVisible="addAnnouncement"
        dialog-confirm-btn-text="预览"
        dialogTitle="添加公告"
        dialogWidth="980px"
        @onClose="addAnnouncement = false"
        @onConfirm="previewAddAnnouncement"
    >
      <div slot="dialog-content" style="padding: 20px 30px">
        <div style="margin-bottom: 5px">
          <div class="notice_title">公告名称</div>
          <input
              v-model="Announcement.title"
              maxlength="20"
              placeholder="限20字"
              style="
              width: 400px;
              height: 40px;
              font-size: 24px;
              border-radius: 4px;
              border: 1px solid #999;
              padding-left: 10px;
            "
              type="text"
          />
        </div>
        <div style="margin-bottom: 10px">
          <div class="notice_title">展示位</div>
          <CSSelect height="40px" iWidth="36px" style="font-size: 24px">
            <select
                v-model="Announcement.type"
                :style="{
                color: Announcement.type != '' ? '#000' : '#999',
              }"
                style="padding-left: 5px"
            >
              <option value="">请选择</option>
              <option
                  v-for="(state, key) in orderTypeStr"
                  :key="key"
                  :value="key"
                  style="color: #000"
              >
                {{ state }}
              </option>
            </select>
          </CSSelect>
        </div>
        <div class="form-item-content" style="margin-bottom: 10px">
          <div class="notice_title">发布时间</div>
          <CSSelect height="40px" iWidth="36px">
            <el-date-picker
                v-model="Announcement.releaseTime"
                :editable="false"
                format="yyyy-MM-dd HH:mm"
                placeholder="请选择"
                style="width: 264px; font-size: 24px; line-height: 30px"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm"
            ></el-date-picker>
          </CSSelect>
        </div>
        <div class="form-item-content" style="margin-bottom: 23px">
          <div class="notice_title">下架时间</div>
          <CSSelect height="40px" iWidth="36px">
            <el-date-picker
                v-model="Announcement.unshelveTime"
                :editable="false"
                format="yyyy-MM-dd HH:mm"
                placeholder="请选择"
                style="width: 264px; font-size: 24px; line-height: 30px"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm"
            ></el-date-picker>
          </CSSelect>
        </div>
        <div style="margin-bottom: 10px">
          <div
              class="notice_title"
              style="
              padding-top: 0;
              vertical-align: top;
              height: 24px;
              line-height: 24px;
            "
          >
            公告列表配图
          </div>
          <div style="display: inline-block; margin-bottom: 20px">
            <div
                v-if="Announcement.picture === ''"
                class="upload-photo"
                style="width: 125px; height: 90px"
                @click="triggerChoosePhoto"
            >
              <div class="text-center">
                <img
                    alt=""
                    src="../../assets/upload.png"
                    style="margin: 5px auto 5px"
                />
                <p style="color: #999">上传照片</p>
                <p style="color: #999">尺寸250*180</p>
              </div>
            </div>
            <div v-else class="photo-view" style="width: 125px; height: 90px">
              <img
                  :src="Announcement.picture"
                  style="width: 125px; height: 90px"
              />
              <i class="icon-close" @click="deletePhotos()"></i>
            </div>
            <input
                id="uploadStaffPhoto"
                accept="images/*"
                class="file"
                hidden
                type="file"
                @change="chooseStaffPhoto($event)"
            />
          </div>
        </div>
        <div>
          <div
              class="notice_title"
              style="
              vertical-align: top;
              padding-top: 0;
              height: 24px;
              line-height: 24px;
            "
          >
            公告内容
          </div>
          <div
              class="edit-visiting-modal-content-item-content"
              style="width: 720px; display: inline-block"
          >
            <WangEditor
                :isSensitive="true"
                parentComponent="AnnouncementAdministrator"
                @callback-photos="updateSensitivePhotos"
            ></WangEditor>
            <div>
              <svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-menua-zu92"></use>
              </svg
              >
              <span
                  style="
                font-weight: 400;
                line-height: 20px;
                color: #999999;
                margin-left: 5px;
              "
              >公告内容请至少上传一张配图</span
              >
            </div>
          </div>
        </div>
      </div>
    </CSDialog>
    <!-- 删除公告弹出框 -->
    <CSDialog
        :dialogVisible="deleteAnnouncement"
        dialog-confirm-btn-text="确定"
        dialog-header-class=" "
        dialog-header-icon="icon-menua-zu13"
        dialogTitle="提示"
        dialogWidth="480px"
        @onClose="deleteAnnouncement = false"
        @onConfirm="DeleteAnnouncement"
    >
      <div
          slot="dialog-content"
          style="padding: 20px 30px; font-size: 24px; text-align: center"
      >
        确定删除吗?
      </div>
    </CSDialog>
    <!-- 上架公告弹出框 -->
    <CSDialog
        :dialogVisible="putawayAnnouncement"
        dialog-confirm-btn-text="确定"
        dialog-header-class=" "
        dialog-header-icon="icon-menua-zu13"
        dialogTitle="提示"
        dialogWidth="480px"
        @onClose="putawayAnnouncement = false"
        @onConfirm="putawayNotice"
    >
      <div
          slot="dialog-content"
          style="padding: 20px 30px; font-size: 24px; text-align: center"
      >
        确定上架吗?
      </div>
    </CSDialog>
    <!-- 下架公告弹出框 -->
    <CSDialog
        :dialogVisible="soldOutAnnouncement"
        dialog-confirm-btn-text="确定"
        dialog-header-class=" "
        dialog-header-icon="icon-menua-zu13"
        dialogTitle="提示"
        dialogWidth="480px"
        @onClose="soldOutAnnouncement = false"
        @onConfirm="soldOutNotice"
    >
      <div
          slot="dialog-content"
          style="padding: 20px 30px; text-align: center; font-size: 24px"
      >
        确定下架吗?
      </div>
    </CSDialog>
    <NoticePhonePreview
        :notice="previewModalInfo.data"
        :visible="previewModalInfo.visible"
        @closeModal="previewModalInfo.onClose"
    >
      <template
          v-if="Object.keys(previewModalInfo.data).length > 0"
          v-slot:btnGroup
      >
        <div v-if="!isPreview" class="text-align: center;padding-top: 10px;">
          <button
              class="btn btn-primary mr-5"
              style="width: 180px; height: 40px"
              @click="
              previewModalInfo.onClose;
              addAnnouncement = true;
              previewModalInfo.visible = false;
            "
          >
            上一步
          </button>
          <!-- addpreviewEditMeetingRoomInfo -->
          <button
              :class="['btn', 'btn-primary', {disabled: isSubmitting}]"
              style="width: 180px; height: 40px"
              @click="commitAnnouncementInfo"
          >
            确认
          </button>
        </div>
        <div v-else style="text-align: center">
          <button
              class="btn"
              style="
                    border-radius: 4px;
                    height: 40px;
                    width: 80px;
                    line-height: 20px;
                    font-size: 24px;
                    color: #fff;
                    background-color: #1ab394;
                    box-shadow: 0 5px 10px 0 rgba(0, 182, 148, 0.2);
                "
              @click="previewModalInfo.onClose"
          >
            关闭
          </button>
        </div>
      </template>
      <!--      <template v-slot:btnGroup v-else>-->

      <!--      </template>-->
    </NoticePhonePreview>
  </div>
</template>

<script>
import {addUrl, adminDelUrl, adminIsEnableUrl, getAdminQueryUrl, userQueryAnPostUrl,} from "@/requestUrl";
import Pagination from "@/components/Pagination.vue";
import WangEditor from "@/components/WangEditor";
import CSSelect from "@/components/common/CSSelect";
import CSDialog from "@/components/common/CSDialog";
import NoticePhonePreview from "@/components/phonePreview/NoticePhonePreview";
import dayjs from "dayjs";
import CSTable from "@/components/common/CSTable";
// 展示位
const SHOWING_STATE = {
  // 小程序
  MIN_PROGRAM: 1,
  // 面板
  FACEPLATE: 2,
};
export default {
  name: "RefundMeetingRoomCharge",
  components: {
    CSTable,
    WangEditor,
    CSSelect,
    NoticePhonePreview,
    Pagination,
    CSDialog,
  },

  data() {
    return {
      isSubmitting: false,
      filterHeight: 0,
      isPreview: false,
      // 控制添加公告弹出框的显示与隐藏
      addAnnouncement: false,
      // 控制删除公告弹出框显示与隐藏
      deleteAnnouncement: false,
      // 控制上架公告弹出框显示与隐藏
      putawayAnnouncement: false,
      // 控制下架公告弹出框显示与隐藏
      soldOutAnnouncement: false,
      AnnouncementModal: false, //控制预览弹出框的显示与隐藏
      startDate: dayjs().subtract(30, "day").format("YYYY-MM-DD") + " 00:00",
      //开始时间 必填
      endDate: dayjs().format("YYYY-MM-DD") + " 23:59", //结束时间   必填
      userInfo: "",
      // 删除公告Id
      currentItemId: "",
      // 上架公告Id
      putawayId: "",
      // 下架公告Id
      soldOutId: "",

      // 添加公告里的数据
      Announcement: {
        regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
        title: "", //公告名称 必填
        releaseTime: "", //发布时间 必填
        unshelveTime: "", //下架时间 必填
        picture: "", //列表配图 必填
        content: "", //公告内容 必填
        photos: [], //公告图片
        type: "", //类型 1.用户小程序2.员工小程序 必填
        userId: this.$vc.getCurrentStaffInfo().id, //员工id 必填
      },
      announcementState: "",
      // 展示位数据
      orderTypeStr: {
        1: "用户小程序",
        2: "员工小程序",
      },
      // 查询状态
      orderStates: [
        {id: 1, name: "未上架"},
        {id: 2, name: "已上架"},
        {id: 3, name: "已下架"},
      ],
      // 存放选择状态
      orderState: {
        1: "未上架",
        2: "已上架",
        3: "已下架",
      },
      noticeState: [
        {id: 1, name: "上架"},
        {id: 2, name: "下架"},
      ],
      noticeList: [], //公告数据
      showingState: {
        [SHOWING_STATE.MIN_PROGRAM]: "小程序",
        [SHOWING_STATE.FACEPLATE]: "面板",
      },
      // 预览弹窗相关信息
      previewModalInfo: {
        visible: false,
        data: {},
        onClose: () => {
          this.previewModalInfo.visible = false;
        },
      },
      pageParams: {
        pageNo: 1,
        pageSize: 10
      }
    };
  },
  destroyed() {
    window.removeEventListener("keydown", this.getNoticeDown);
  },
  deactivated() {
    window.removeEventListener("keydown", this.getNoticeDown);
  },
  methods: {
    getNoticeDown(e) {
      if (e.keyCode == 13) {
        this.getNotice();
      }
    },
    // 添加公告时预览操作
    previewAddAnnouncement() {
      if (this.Announcement.picture === "") {
        this.$vc.toast("请上传配图");
        return;
      }
      this.previewModalInfo.data = this.Announcement;
      this.addAnnouncement = false;
      this.previewModalInfo.visible = true;
    },
    updateSensitivePhotos(src) {
      this.Announcement.photos.push(src);
    },
    // 查看公告信息
    async lookAnnouncementInformation(id) {
      const res = await this.getAnnouncementParticulars(id);
      if (res.code != 0) {
        this.$vc.message("获取到公告详细信息失败");
        return;
      }
      this.previewModalInfo.data = res.data;
      this.previewModalInfo.visible = true;
      this.isPreview = true;
    },
    // 获取公告详情
    getAnnouncementParticulars(id) {
      return this.$fly
          .post(userQueryAnPostUrl, {
            regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
            id, //帖子id/活动帖子id  必填
            userId: this.$vc.getCurrentStaffInfo().id, //用户id 必填
          })
          .then((res) => {
            if (res.code != 0) {
              return;
            }
            res.data.photos = JSON.parse(res.data.photos);
            return res;
          });
    },

    // 查询公告
    getNotice(pageNo = this.pageParams.pageNo, pageSize = 10) {
      this.$fly
          .post(getAdminQueryUrl, {
            regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
            startDate: this.startDate, //查询开始时间 必填
            endDate: this.endDate, //查询结束时间 必填
            title: this.userInfo, //公告名称
            state: this.announcementState, //公告状态 1.未上架2.已上架3.已下架
            pageNo,
            pageSize,
            type: 1,
          })
          .then((res) => {
            if (res.code != 0) {
              return;
            }
            if (pageNo === 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total,
                currentPage: pageNo,
                pageSize: pageSize,
              });
            }
            this.noticeList = res.data.datas;
          });
    },
    // 删除公告
    DeleteAnnouncement() {
      this.deleteAnnouncement = true;
      this.$fly
          .post(adminDelUrl, {
            regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
            id: this.currentItemId, //公告id 必填
            userId: this.$vc.getCurrentStaffInfo().id, //操作人id 必填
          })
          .then((res) => {
            if (res.code != 0) {
              return;
            }
            this.deleteAnnouncement = false;
            this.getNotice();
          });
    },
    // 后台上架公告
    putawayNotice() {
      this.putawayAnnouncement = true;
      this.$fly
          .post(adminIsEnableUrl, {
            regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
            id: this.putawayId, //公告id 必填
            state: 2, //2.上架3.下架 必填
          })
          .then((res) => {
            if (res.code != 0) {
              return;
            }
            this.putawayAnnouncement = false;
            this.getNotice();
          });
    },
    // 后台下架公告
    soldOutNotice() {
      this.soldOutAnnouncement = true;
      this.$fly
          .post(adminIsEnableUrl, {
            regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
            id: this.soldOutId, //公告id 必填
            state: 3, //2.上架3.下架 必填
          })
          .then((res) => {
            if (res.code != 0) {
              return;
            }
            this.soldOutAnnouncement = false;
            this.getNotice();
          });
    },
    openAddAnnouncement() {
      this.addAnnouncement = true;
      this.isPreview = false;
      this.Announcement = {
        regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
        title: "", //公告名称 必填
        releaseTime: "", //发布时间 必填
        unshelveTime: "", //下架时间 必填
        picture: "", //列表配图 必填
        content: "", //公告内容 必填
        photos: [], //公告图片
        type: "", //类型 1.用户小程序2.员工小程序 必填
        userId: this.$vc.getCurrentStaffInfo().id, //员工id 必填
      };
    },
    //  调起选择文件
    triggerChoosePhoto() {
      $("#uploadStaffPhoto").trigger("click");
    },
    // 上传图片
    async uploadPhoto(base64) {
      return new Promise((reslove, reject) => {
        this.$vc.http.post(
            "uploadImg",
            "upload",
            {
              img: base64,
            },
            {
              headres: {
                "Content-Type": "application/json",
              },
            },
            (resStr, res) => {
              reslove(res.body.fileSaveName);
            },
            (errText, err) => {
              reject(errText);
            }
        );
      });
    },
    // 选择文件后处理
    async chooseStaffPhoto(event) {
      var photoFiles = event.target.files;
      if (photoFiles && photoFiles.length > 0) {
        // 获取目前上传的文件
        var file = photoFiles[0]; // 文件大小校验的动作
        if (file.size > 1024 * 1024 * 2) {
          this.$vc.toast("图片大小不能超过 2MB!");
          return false;
        }
        let formData = new FormData(); //新建FormData对象
        formData.append("files", photoFiles[0]);
        formData.append("bucketName", this.Constants.BUCKET_NAMES.IMG.name);
        formData.append("compress", "true");
        const photo = await this.Constants.uploadFileOne(formData);
        if (photo) {
          this.Announcement.picture = this.Constants.BUCKET_NAMES.IMG.visitUrl + photo;
        }
      }
    },
    deletePhotos() {
      this.Announcement.picture = "";
    },

    // 添加公告
    async commitAnnouncementInfo() {
      if (this.isSubmitting) {
        this.$vc.message('请不要重复提交');
        return;
      }
      let params = {...this.Announcement};
      this.isSubmitting = true;
      if (this.previewModalInfo.data.id) {
        params.id = this.previewModalInfo.data.id;
        params.photos = JSON.stringify(params.photos);
        params.regionId = this.$vc.getCurrentRegion().communityId;
        await this.amendVisitingServiceItem(this.$vc.removeNullProperty(params));
      } else {
        await this.addPreviewAnnouncementInfo(this.$vc.removeNullProperty(params));
      }
      this.isSubmitting = false;
      this.getNotice();
    },
    // 提交添加的公告
    addPreviewAnnouncementInfo(params) {
      params.photos = JSON.stringify(params.photos);
      return this.$fly.post(addUrl, params).then((res) => {
        if (res.code != 0) {
          return;
        }
        this.$vc.toast("操作成功");
        this.closeMeetingRoomView();
        this.getNotice();
        return;
      })
          .catch(err => err);
    },
    // 关闭预览弹窗
    closeMeetingRoomView() {
      this.previewModalInfo.onClose();
      this.AnnouncementModal = false;
    },
  },
  mounted() {
    this.getNotice();
    this.$vc.on(this.$route.path, "AnnouncementAdministrator", "changeEditor", (html) => {
      this.Announcement.content = html;
    });
    window.addEventListener("keydown", this.getNoticeDown);
    this.$vc.on(this.$route.path, "pagination", "page_event", (_currentPage) => {
      this.pageParams.pageSize = _currentPage;
      this.getNotice(_currentPage);
    });
    this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
    window.addEventListener('resize', () => {
      this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
    })
  },
};
</script>

<style scoped>
#proclamation_ .el-dialog__header {
  padding: 0;
}

#proclamation_ .el-dialog__footer {
  padding: 30px 0;
  margin: auto;
  text-align: center;
  border-top: 1px solid #999;
}
</style>

<style lang="stylus" scoped>
.underline_ {
  text-decoration: underline;
}

.btn_query {
  position: relative;
  top: 1.4px;
  left: 0;
  width: 50px;
  height: 31px;
  background: #1ab394;
  box-shadow: 0px 5px 10px 0px rgba(0, 182, 148, 0.2);
  border-radius: 4px;
  border: none;
  font-size: 14px;
  color: #fff;
  margin-left: 30px;
}

/deep/ .el-input__inner {
  &::placeholder {
    color: #999;
  }
}

/deep/ .el-input__prefix {
  line-height: 20px;
}

.cancel_ {
  width: 80px;
  height: 40px;
  background: #FF9F00;
  box-shadow: 0px 5px 10px 0px rgba(255, 159, 0, 0.3);
  border-radius: 6px;
  color: #fff;
  font-size: 24px;
  border: none;
  margin-right: 60px;
}

.preview_ {
  width: 80px;
  height: 40px;
  background: #1ab394;
  box-shadow: 0px 5px 10px 0px rgba(0, 182, 148, 0.3);
  border-radius: 6px;
  color: #fff;
  font-size: 24px;
  border: none;
}

.notice_name {
  width: 400px;
  height: 40px;
  font-size: 24px;
  border: 1px solid #999;
  border-radius: 4px;
  padding-left: 10px;
}

.notice_title {
  display: inline-block;
  width: 144px;
  font-size: 24px;
  color: #000;
  text-align: right;
  margin: 0 40px 15px 0;
  padding-top: 5px;
  vertical-align: middle;
}

.el-dialog .cs-select {
  width: 251px;
  height: 40px;
  font-size: 24px;
}

.el-dialog .cs-select .icon {
  width: 36px;
  line-height: var(--icon-line-height, 40px);
}

.attentionMatters p {
  margin-bottom: 0;
  margin-left: 30px;
  color: #999;
  font-size: 20px;
}

.determine {
  width: 80px;
  height: 40px;
  background: #1ab394;
  box-shadow: 0px 5px 10px 0px rgba(0, 182, 148, 0.3);
  border-radius: 6px;
  color: #fff;
  border: none;
  font-size: 24px;
}

.btn-warn {
  width: 80px;
  background-color: #FF9F00;
  color: #fff;
  font-size: 24px;
  line-height: 40px;
  padding: 0;
  border-radius: 4px;
}
</style>
